import { LoadingButton } from '@mui/lab';
import { Box, SxProps } from '@mui/material';
import { FC } from 'react';

import { sxButton } from './styles';

type FooterProps = {
  disabled?: boolean;
  isLoading?: boolean;
  sx?: SxProps;
  handleSubmit: () => void;
  title?: string;
};

const Footer: FC<FooterProps> = ({ disabled, isLoading = false, sx = {}, handleSubmit, title }) => {
  return (
    <Box sx={sx}>
      <LoadingButton
        disabled={disabled}
        loading={isLoading}
        variant="contained"
        color="primary"
        sx={sxButton}
        size="large"
        onClick={handleSubmit}
      >
        {title || 'Save Changes'}
      </LoadingButton>
    </Box>
  );
};

export default Footer;
