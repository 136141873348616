import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useCreatePieceAnnotationMutation } from '@api/pieceAnnotation';
import { AnnotationType, EmptyResultResponse, PieceAnnotation } from '@apiContract';
import { yupResolver } from '@hookform/resolvers/yup';

type FormValues = {
  pieceId: number;
  textValue: string;
  created: string;
  updated: string;
  annotationType: number;
};

type FormProps = {
  pieceId: number;
  onClose: () => void;
  onCreate?: (piceAnnotation: PieceAnnotation) => void
};

const schema = yup.object().shape({
  pieceId: yup.number().required(),
  textValue: yup.string().required(),
  created: yup.string(),
  updated: yup.string(),
  annotationType: yup.number(),
});

const Form: FC<FormProps> = ({ pieceId, onClose, onCreate }) => {
  const [createPieceAnnotation, { isLoading }] = useCreatePieceAnnotationMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: { pieceId: pieceId, annotationType: AnnotationType.TrackingNumber },
  });

  const onSubmit = async (formData: FormValues) => {
    try {
      const annotation = await createPieceAnnotation({ ...formData }).unwrap();
      if (onCreate) {
        onCreate(annotation.objectData);
      }
      onClose();
    } catch (e) {
      const data = (e as { status: number; data: EmptyResultResponse }).data;
      if (data && data.userMessage) {
        setError('textValue', { message: data.userMessage });
      }
    }
  };

  return (
    <Paper sx={{ bgcolor: 'background.neutral', p: 2.5 }}>
      <Box
        component="form"
        display="flex"
        onSubmit={handleSubmit(onSubmit)}
        justifyContent="space-between"
        flexDirection="column"
        gap={3}
        noValidate
        autoComplete="off"
      >
        <Typography variant="subtitle1">Add tracking number</Typography>

        <TextField
          fullWidth
          label="Tracking number"
          inputProps={{ ...register('textValue') }}
          error={!!errors.textValue}
          helperText={errors.textValue?.message}
          required
        />
        <Box display="flex" gap={1.5} justifyContent="flex-end">
          <Button variant="outlined" size="large" sx={{ border: 0 }} onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary" size="large" disabled={isLoading}>
            Save Tracking Number
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default Form;
