import type { Theme } from '@mui/material';

export const sxBackBtn = {
  display: { xs: 'flex', md: 'none' },
};

export const sxTitle = {
  position: { xs: 'absolute', md: 'static' },
  left: '50%',
  top: '50%',
  transform: { xs: 'translate(-50%, -50%)', md: 'unset' },
};

export const sxSearchIcon = {
  color: 'text.disabled',
};

export const sxSearchCircle = (theme: Theme) => ({
  color: theme.palette.grey[500],
});

export const sxButton = {
  px: '22px',
  py: '11px',
  width: 1,
};