import { Box, Paper } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { GetUsersFilteredRequest, useGetUsersFilteredQuery } from '@api/user';
import { Piece, RecipientAutoDetection, RecipientAutoDetectionStatus, User } from '@apiContract';

import { useAssignmentRecipient } from '@common/components/Capture/hooks/useAssignmentRecipient';
import { AddEvent } from '@common/components/Capture/hooks/useUpdateQueue';
import Footer from '../Footer';
import Search from '../Search';
import Title from '../Title';
import AssignTO from './components/AssignTo';
import List from './components/List';
import UserForm from './components/UserForm';
import { sxP20, sxPaper, sxTab, sxTabsSection } from './styles';

const ITEMS_PER_PAGE = 20;

const initialFilters = {
  UserRole: ['Recipient'],
  ItemsPerPage: ITEMS_PER_PAGE,
  PageNumber: 0,
  SearchText: '',
  UserStatus: 1,
};

type UserListData = {
  piece: Piece;
  pieceDetection?: RecipientAutoDetection;
  error: unknown;
  loading: boolean;
};

type UserListProps = {
  file: UserListData;
  onClose: () => void;
  addEvent: (param: AddEvent) => void;
};

const UserList = (props: UserListProps) => {
  const { file, onClose, addEvent } = props;
  const { pieceDetection, piece, error, loading } = file;
  const [tabIndex, setTabIndex] = useState(0);
  const [filters, setFilters] = useState<GetUsersFilteredRequest>({
    ...initialFilters,
    SearchText: pieceDetection?.recipientSearchString ?? '',
  });

  const [items, setItems] = useState<Array<User>>([]);
  const [selectedUser, setSelectedUser] = useState<User | undefined | null>(piece?.recipient);
  const { data = {}, isFetching } = useGetUsersFilteredQuery(filters);
  const { assignRecipientToPiece } = useAssignmentRecipient(addEvent);

  const loadNextPage = useCallback((startIndex: number) => {
    setFilters((prev) => ({
      ...prev,
      PageNumber: Math.floor(startIndex / ITEMS_PER_PAGE),
      ItemsPerPage: ITEMS_PER_PAGE,
    }));
  }, []);

  const selectUser = (user: User) => {
    setSelectedUser(user);
  };

  useEffect(() => {
    if (data.pageNumber === 0) {
      setItems(data.items || []);
    } else {
      setItems((prev) => [...prev, ...(data.items || [])]);
    }
  }, [data]);

  if (
    !selectedUser &&
    pieceDetection?.recipient &&
    pieceDetection?.status === RecipientAutoDetectionStatus.IdentifiedVerificationRequired
  ) {
    setSelectedUser(pieceDetection.recipient);
  }

  const onSearch = (value: string) => {
    setFilters({ ...initialFilters, SearchText: value, PageNumber: 0 });
  };

  const changeTab = () => setTabIndex((prev) => (!prev ? 1 : 0));

  const hasNextPage = (data.totalCount || 0) > items.length;

  const addUserToPiece = (user?: User) => {
    const currentUser = user || selectedUser;

    if (currentUser) {
      assignRecipientToPiece(currentUser, piece);
    }

    if (user) {
      setSelectedUser(user);
    }
  };

  const handleSubmit = () => {
    addUserToPiece();
    onClose();
  };

  const isConfirm =
    selectedUser &&
    piece?.recipient &&
    pieceDetection?.status === RecipientAutoDetectionStatus.IdentifiedVerificationRequired;

  return (
    <Paper elevation={3} sx={{ ...sxPaper }}>
      <Title title="Assign" sx={sxP20} onClose={onClose} />
      <Box component="section" sx={{ ...sxTabsSection(tabIndex) }}>
        <Box display="flex" sx={sxTab}>
          <AssignTO
            user={selectedUser}
            recipientStatus={pieceDetection?.status}
            piece={piece}
            showAlert={
              !error &&
              !!pieceDetection &&
              (!piece?.recipient || pieceDetection?.recipientId === piece?.recipient?.id)
            }
            loading={loading || (!piece.recipientAutoDetection && !pieceDetection)}
          />
          <Search
            setSearchResult={onSearch}
            sx={sxP20}
            defaultSearch={pieceDetection?.recipientSearchString}
            isLoading={isFetching}
          />
          <List
            changeTab={changeTab}
            loadNextPage={loadNextPage}
            isNextPageLoading={isFetching}
            items={items}
            setSelectedUser={selectUser}
            selectedUser={selectedUser}
            hasNextPage={hasNextPage}
          />
          <Footer
            sx={sxP20}
            disabled={!selectedUser?.id || selectedUser?.id === piece?.recipient?.id}
            title={isConfirm ? 'Confirm Recipient' : 'Save Changes'}
            handleSubmit={handleSubmit}
          />
        </Box>
        <UserForm changeTab={changeTab} assignUser={addUserToPiece} />
      </Box>
    </Paper>
  );
};

export default UserList;