import { Paper, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';

import { useGetContainerTypesQuery } from '@api/container';
import { Piece, PieceType } from '@apiContract';
import StorageGroup from 'src/common/components/StorageGroup';
import { WorkflowEnumByName, WorkflowMail, WorkflowPackages } from 'src/common/types/contractHelpers';

import Footer from '@common/components/DrawerContent/Footer';
import Title from '@common/components/DrawerContent/Title';
import { sxMain, sxP20, sxPaper, sxSection } from './styles';
import { useLocationId } from './useLocationId';
import { getPieceTypeByWorkflow } from 'src/common/utils/piece';

type LocationIDProps = {
  piece: Piece;
  onChange: (data: Piece) => void;
  onClose: () => void;
};

const LocationID = ({ piece, onChange, onClose }: LocationIDProps) => {
  const [isLoadingContainerByReq, setIsLoading] = useState(false);
  const [itemSpot, setItemSpot] = useState<string>(piece?.pieceLocation?.packageSpot || '');

  const itemType = getPieceTypeByWorkflow(piece.workflow);

  const { pieceLocation, id } = piece;
  const { containerType, allCount } = pieceLocation.container;
  const { workflow } = containerType;
  const { data: containerTypes = [] } = useGetContainerTypesQuery();
  const { setStorageGroupData, onSaveLocation, isLoading: updatePieceLoading } = useLocationId(id, onChange, onClose);
  return (
    <Paper elevation={3} sx={sxPaper}>
      <Title title="Location ID" sx={sxP20} onClose={onClose} />
      <Stack spacing={3} sx={sxMain}>
        <Typography variant="subtitle1">{pieceLocation.container.containerTitleFormatted}</Typography>
        <Stack sx={sxSection} spacing={3}>
          <Stack direction="row" spacing={0.8}>
            <Typography variant="subtitle1">Storage Group</Typography>
            <Typography variant="body1" color="text.secondary">
              ({allCount} item{allCount === 1 ? '' : 's'})
            </Typography>
          </Stack>
          <StorageGroup
            workflow={WorkflowEnumByName[workflow as WorkflowMail | WorkflowPackages]}
            itemType={itemType}
            defaultValue={{
              createdDate: pieceLocation.container.createdDate,
              containerTypeId: pieceLocation.container.containerType.id,
              containerNumber: pieceLocation.container.containerNumber,
              sortOrder: pieceLocation.container.sortOrder,
            }}
            containerTypes={containerTypes}
            direction="column"
            onChange={setStorageGroupData}
            isNextOrderNo
            onLoading={setIsLoading}
            isLoading={isLoadingContainerByReq}
          />
        </Stack>
        {itemType === PieceType.Package && (
          <Stack sx={sxSection} spacing={3}>
            <Typography variant="subtitle1">Item</Typography>
            <TextField
              value={itemSpot}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setItemSpot(event.target.value)}
              fullWidth
              label="Spot"
            />
          </Stack>
        )}
      </Stack>
      <Footer
        sx={sxP20}
        isLoading={updatePieceLoading || isLoadingContainerByReq}
        handleSubmit={() => onSaveLocation(itemType, itemSpot)}
      />
    </Paper>
  );
};

export default LocationID;
