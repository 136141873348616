import { type Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';

export const sxMain = {
  bgcolor: 'background.default',
  px: { xs: 2, sm: 3, md: 5 },
  pt: { xs: 2, sm: 3, md: 11 },
  pb: { xs: 8.5, md: 11 },
  flex: 1,
};

export const sxList = (theme: Theme, filesLength: number) => ({
  my: 3,
  flex: 1,
  minHeight: filesLength > 3 ? '200px' : `${filesLength * 70}px`,
  '& .customScrollBar': {
    '&::-webkit-scrollbar': {
      width: '6px',
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.grey[800],
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[700],
      borderRadius: '12px',
    },
  },
});

export const sxDropBox = (isDragActive: boolean, disabled: boolean, hasError: boolean) => ({
  p: { xs: 2, sm: 3, md: 5 },
  outline: 'none',
  borderRadius: 1,
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
  bgcolor: (theme: Theme) => alpha(theme.palette.grey[500], 0.08),
  border: (theme: Theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
  transition: (theme: Theme) => theme.transitions.create(['opacity', 'padding']),
  '&:hover': {
    opacity: 0.72,
  },
  ...(isDragActive && {
    opacity: 0.72,
  }),
  ...(disabled && {
    opacity: 0.48,
    pointerEvents: 'none',
  }),
  ...(hasError && {
    color: 'error.main',
    borderColor: 'error.main',
    bgcolor: (theme: Theme) => alpha(theme.palette.error.main, 0.08),
  }),
});

export const sxUpload = {
  width: 1,
  maxWidth: 1200,
  mx: 'auto',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: 1,
};

export const sxBrowse = {
  mx: 0.5,
  color: 'primary.main',
  textDecoration: 'underline',
};

export const sxIllustration = {
  width: 1,
  maxWidth: 200,
  height: { xs: 100, md: 'auto' },
};

export const sxRejection = {
  py: 1,
  px: 2,
  mt: 3,
  textAlign: 'left',
  borderStyle: 'dashed',
  borderColor: 'error.main',
  bgcolor: (theme: Theme) => alpha(theme.palette.error.main, 0.08),
};

export const sxRow = {
  my: 1,
  py: 0.75,
  px: 1,
  borderRadius: 1,
  border: (theme: Theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
};

export const sxAlert = {
  mb: 3,
};
