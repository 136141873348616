import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, Button, Divider, SxProps, Typography } from '@mui/material';
import { FC } from 'react';

import { sxBackBtn, sxTitle } from './styles';

type TitleProps = {
  sx?: SxProps;
  title: string;
  onClose: () => void;
};

const Title: FC<TitleProps> = ({ sx = {}, title, onClose }) => {
  return (
    <>
      <Box sx={sx} position="relative">
        <Button onClick={onClose} variant="text" startIcon={<ArrowBackIosNewIcon />} sx={sxBackBtn}>
          Back
        </Button>
        <Typography sx={sxTitle} variant="h6">
          {title}
        </Typography>
      </Box>
      <Divider />
    </>
  );
};

export default Title;
