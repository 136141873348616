import { ContainerSortOrder, Workflow } from './apiContract';

export enum SortingOrder {
  addToFront = 'AddToFront',
  addToBack = 'AddToBack',
  none = 'None',
}

export enum WorkflowMail {
  dateReceived = 'DateReceived',
  highDensityMail = 'HighDensityMail',
  mailBox = 'MailBox',
}

export enum WorkflowPackages {
  location = 'Location',
  carrier = 'Carrier',
  lockers = 'Lockers',
}

export enum ItemValue {
  mail = 'Mail',
  packages = 'Packages',
}

export const SortingOrderNameByEnum = {
  [SortingOrder.addToBack]: ContainerSortOrder.AddToBack,
  [SortingOrder.addToFront]: ContainerSortOrder.AddToFront,
  [SortingOrder.none]: ContainerSortOrder.None,
};

export const WorkflowNameByEnum = {
  [Workflow.DateReceived]: WorkflowMail.dateReceived,
  [Workflow.HighDensityMail]: WorkflowMail.highDensityMail,
  [Workflow.MailBox]: WorkflowMail.mailBox,
  [Workflow.Location]: WorkflowPackages.location,
  [Workflow.Carrier]: WorkflowPackages.carrier,
  [Workflow.Lockers]: WorkflowPackages.lockers,
};

export const WorkflowEnumByName = {
  [WorkflowMail.dateReceived]: Workflow.DateReceived,
  [WorkflowMail.highDensityMail]: Workflow.HighDensityMail,
  [WorkflowMail.mailBox]: Workflow.MailBox,
  [WorkflowPackages.location]: Workflow.Location,
  [WorkflowPackages.carrier]: Workflow.Carrier,
  [WorkflowPackages.lockers]: Workflow.Lockers,
};

export enum ReceiveSettingsName {
  DefaultItemType = 'DefaultItemType',
  ReceiveMailWorkflow = 'ReceiveMailWorkflow',
  ReceiveSortOrder = 'ReceiveSortOrder',
  ReceivePackagesWorkflow = 'ReceivePackagesWorkflow',
}
