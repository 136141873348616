import {
  CaptureMethod,
  Piece,
  PieceAction,
  PieceAnnotation,
  PieceAsset,
  PieceAssetType,
  RecipientAutoDetection,
  RecipientAutoDetectionStatus,
} from '@apiContract';
// eslint-disable-next-line import/named
import { assoc, compose, isArray, path, remove, unionBy, unset } from 'lodash/fp';
import { v4 } from 'uuid';

import { Options } from '@common/types/capture';

import { CaptureId, DrawerTypes, MapCapturePiece, MapDetectedRecipient, PieceId, Steps } from '../types';
import { type AddEvent } from './useUpdateQueue';

export enum ActionTypes {
  INIT_FILE = 'INIT_FILE',
  SET_CURSOR = 'SET_CURSOR',
  SET_CAPTURE_STATE = 'SET_CAPTURE_STATE',
  REMOVE_FILE = 'REMOVE_FILE',
  SET_RECEIVE_SESSION = 'SET_RECEIVE_SESSION',
  SET_FILE_OPTION = 'SET_FILE_OPTION',
  UPDATE_FILE_ID = 'UPDATE_FILE_ID',
  SET_FILE_DETECTION = 'SET_FILE_DETECTION',
  SET_DRAWER = 'SET_DRAWER',
  SET_CROP_RETURN_PATH = 'SET_CROP_RETURN_PATH',
  SET_UPLOAD_ERROR = 'SET_UPLOAD_ERROR',
  CHECK_NOT_SET_RECIPIENT = 'CHECK_NOT_SET_RECIPIENT',
  UPDATE_RECEIVE_CONFIG = 'UPDATE_RECEIVE_CONFIG',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_PIECE_ASSETS = 'SET_PIECE_ASSETS',
  ADD_PIECE_LOAD_LIST = 'ADD_PIECE_LOAD_LIST',
  SET_PIECE_BARCODES = 'SET_PIECE_BARCODES',
  MERGE_PIECE_BARCODES = 'MERGE_PIECE_BARCODES',
}

export type Store = {
  files: MapCapturePiece;
  captureState: Steps;
  cursor: CaptureId | null;
  drawer: DrawerTypes | null;
  receiveSession: string | null;
  cropReturnPath: Steps | null;
  uploadError: boolean;
  detectedRecipients: MapDetectedRecipient;
  options: Options;
  isLoading: false;
  loadPercent: number;
};

const getCaptureState = (options: Options, isNew: boolean) => {
  switch (true) {
    case !isNew:
      return Steps.Loading;

    case !!options.captureMethod && !!options.pieceType && !!options.workflow && !!options.storageGroupData:
      return options.captureMethod === CaptureMethod.Single ? Steps.Photo : Steps.Images;

    default:
      return Steps.ReceiveConfig;
  }
};

export const initStore = (options: Options, isNew = false): Store => {
  return {
    files: {},
    captureState: getCaptureState(options, isNew),
    cursor: null,
    drawer: null,
    receiveSession: null,
    cropReturnPath: null,
    uploadError: false,
    detectedRecipients: {},
    options,
    isLoading: false,
    loadPercent: 0,
  };
};

type InitFileAction = {
  type: ActionTypes.INIT_FILE;
  payload: {
    id?: string;
    photo: File | string;
  };
};

type SetCursorAction = {
  type: ActionTypes.SET_CURSOR;
  payload: CaptureId | null;
};

type SetCaptureStateAction = {
  type: ActionTypes.SET_CAPTURE_STATE;
  payload: Steps;
};

type RemoveFileAction = {
  type: ActionTypes.REMOVE_FILE;
  payload: CaptureId;
};

type SetReceiveSessionAction = {
  type: ActionTypes.SET_RECEIVE_SESSION;
  payload: string;
};

export type FileOptionPayload = {
  id: CaptureId | CaptureId[];
  option: string | string[];
  value: any;
  cb?: (a: Store) => void;
};

type SetFileOption = {
  type: ActionTypes.SET_FILE_OPTION;
  payload: FileOptionPayload;
};

type UpdateFileId = {
  type: ActionTypes.UPDATE_FILE_ID;
  payload: CaptureId;
};

type SetFileDetection = {
  type: ActionTypes.SET_FILE_DETECTION;
  payload: {
    id: CaptureId;
    detection: any;
    addEvent?: (a: any) => void;
  };
};

type SetDrawerType = {
  type: ActionTypes.SET_DRAWER;
  payload: DrawerTypes | null;
};

type SetCropReturnPath = {
  type: ActionTypes.SET_CROP_RETURN_PATH;
  payload: Steps;
};

type SetUploadError = {
  type: ActionTypes.SET_UPLOAD_ERROR;
  payload: boolean;
};

type CheckNotSetRecipientAction = {
  type: ActionTypes.CHECK_NOT_SET_RECIPIENT;
  payload: {
    id: PieceId;
    addEvent: (data: AddEvent) => void;
  };
};

type UpdateReceiveConfig = {
  type: ActionTypes.UPDATE_RECEIVE_CONFIG;
  payload: Partial<Options>;
};

type SetIsLoading = {
  type: ActionTypes.SET_IS_LOADING;
  payload: boolean;
};

type SetPieceAssets = {
  type: ActionTypes.SET_PIECE_ASSETS;
  payload: {
    id: CaptureId;
    value: Array<PieceAsset>;
  };
};

type AddPiceLoadList = {
  type: ActionTypes.ADD_PIECE_LOAD_LIST;
  payload: Piece[];
};

type SetUpBarcodes = {
  type: ActionTypes.SET_PIECE_BARCODES | ActionTypes.MERGE_PIECE_BARCODES;
  payload: PieceAnnotation[];
};

export type Actions =
  | UpdateFileId
  | InitFileAction
  | SetCursorAction
  | SetCaptureStateAction
  | RemoveFileAction
  | SetReceiveSessionAction
  | SetFileOption
  | SetFileDetection
  | SetDrawerType
  | SetCropReturnPath
  | SetUploadError
  | CheckNotSetRecipientAction
  | UpdateReceiveConfig
  | SetIsLoading
  | SetPieceAssets
  | AddPiceLoadList
  | SetUpBarcodes;

const setFileOptionValue = (state: Store, { id, option, value }: FileOptionPayload): Store => {
  return assoc(['files', isArray(id) ? id[0] : id, ...(isArray(option) ? option : [option])], value)(state);
};

export const reducer = (state: Store, { type, payload }: Actions): Store => {
  switch (type) {
    case ActionTypes.INIT_FILE: {
      const id = payload.id || v4();
      return compose(
        assoc(['files', id], {
          id,
          photo: payload.photo,
          status: 'created',
          pieceDetection: null,
          selectedPieceDetection: null, // still need?
          selectedUserDrawer: false,
          isUpdating: false,
          piece: null,
          assignUserError: false,
          isAutoCroppedCompleted: false,
          pieceAnnotations: null,
        }),
        assoc(['cursor'], id),
      )(state);
    }

    case ActionTypes.SET_CURSOR: {
      return assoc(['cursor'], payload, state);
    }

    case ActionTypes.SET_CAPTURE_STATE:
      return assoc(['captureState'], payload, state);

    case ActionTypes.REMOVE_FILE:
      return unset(['files', payload], state);

    case ActionTypes.SET_RECEIVE_SESSION:
      return assoc(['receiveSession'], payload, state);

    case ActionTypes.SET_FILE_OPTION: {
      const { id, cb } = payload;
      let store;
      if (isArray(id)) {
        store = id.reduce((acc, nextId) => setFileOptionValue(acc, { ...payload, id: nextId }), state);
      } else {
        store = setFileOptionValue(state, payload);
      }
      if (cb) cb(store);
      return store;
    }

    case ActionTypes.UPDATE_FILE_ID: {
      if (!state.files[payload] || !state.files[payload].piece) return state;
      let nextState = { ...state };
      if (state.cursor && state.cursor === payload) {
        nextState = assoc(['cursor'], state.files[state.cursor].piece?.id, nextState);
      }
      // it's impossible to be null here but typescript doesn't like it
      const nextId = state?.files?.[payload]?.piece?.id || 0;
      return compose(
        unset(['files', payload]),
        assoc(['files', nextId, 'id'], nextId),
        assoc(['files', nextId], state.files[payload]),
      )(nextState);
    }

    case ActionTypes.SET_FILE_DETECTION: {
      const { id, detection, addEvent } = payload as {
        id: number;
        detection: RecipientAutoDetection;
        addEvent: (data: AddEvent) => void;
      };
      if (state.files[id]?.piece && !state.files[id]?.piece?.recipient) {
        let isUpdating = false;

        if (
          addEvent &&
          !!detection.recipientId &&
          (detection.status === RecipientAutoDetectionStatus.Identified ||
            detection.status === RecipientAutoDetectionStatus.IdentifiedBasedOnPreviousAssignment) &&
          !(state.cursor === id && state.drawer === DrawerTypes.User)
        ) {
          addEvent({
            event: { id, recipientId: detection.recipientId },
            action: PieceAction.Assign,
            source: 'signalr',
          });
          isUpdating = true;
        }
        return compose(
          assoc(['files', id, 'isUpdating'], isUpdating),
          assoc(['files', id, 'pieceDetection'], detection),
          assoc(['files', id, 'status'], 'detected'),
        )(state);
      } else {
        return assoc(['detectedRecipients', id], detection, state);
      }
    }

    case ActionTypes.SET_DRAWER:
      return assoc(['drawer'], payload, state);

    case ActionTypes.SET_CROP_RETURN_PATH:
      return assoc(['cropReturnPath'], payload, state);

    case ActionTypes.SET_UPLOAD_ERROR:
      return assoc(['uploadError'], payload, state);

    case ActionTypes.CHECK_NOT_SET_RECIPIENT: {
      const { id, addEvent } = payload;
      const recipient = state.detectedRecipients[id] || null;

      if (recipient) {
        if (
          recipient.recipientId &&
          (recipient.status === RecipientAutoDetectionStatus.Identified ||
            recipient.status === RecipientAutoDetectionStatus.IdentifiedBasedOnPreviousAssignment) &&
          !(state.cursor === id && state.drawer === DrawerTypes.User)
        ) {
          addEvent({
            event: { id, recipientId: recipient.recipientId },
            action: PieceAction.Assign,
            source: 'signalr',
          });
        }
        return compose(
          unset(['detectedRecipients', id]),
          assoc(['files', id, 'pieceDetection'], recipient),
          assoc(['files', id, 'status'], 'detected'),
        )(state);
      }

      return state;
    }
    case ActionTypes.UPDATE_RECEIVE_CONFIG: {
      return assoc('options', { ...state.options, ...payload }, state);
    }

    case ActionTypes.SET_IS_LOADING: {
      return assoc('isLoading', payload, state);
    }

    case ActionTypes.SET_PIECE_ASSETS: {
      const { id, value } = payload;

      if (!state.files[id]) return state;

      if (value.length && value[0].pieceAssetType === PieceAssetType.Cropped) {
        const data = compose(
          remove((n: PieceAsset) => n.pieceAssetType === value[0].pieceAssetType),
          path(['files', id, 'piece', 'pieceAssets']),
        )(state);

        return assoc(['files', id, 'piece', 'pieceAssets'], [...data, ...value], state);
      }

      return assoc(
        ['files', id, 'piece', 'pieceAssets'],
        [...(state.files[id].piece?.pieceAssets || []), ...value],
        state,
      );
    }

    case ActionTypes.ADD_PIECE_LOAD_LIST: {
      return assoc(
        'files',
        {
          ...state.files,
          ...payload.reduce((acc, item) => {
            acc[item.id] = {
              id: item.id,
              status: 'loadedFromServer',
              pieceDetection: item.recipientAutoDetection || {},
              selectedPieceDetection: null,
              isUpdating: false,
              piece: item,
              assignUserError: false,
              isAutoCroppedCompleted: false,
              pieceAnnotations: null,
            };
            return acc;
          }, {} as MapCapturePiece),
        },
        state,
      );
    }

    case ActionTypes.SET_PIECE_BARCODES: {
      return assoc(
        'files',
        {
          ...state.files,
          ...payload.reduce((acc, item) => {
            acc[item.pieceId] = {
              ...state.files[item.pieceId],
              pieceAnnotations: unionBy('id', acc[item.pieceId]?.pieceAnnotations || [], [item]),
            };
            return acc;
          }, {} as MapCapturePiece),
        },
        state,
      );
    }

    case ActionTypes.MERGE_PIECE_BARCODES: {
      return assoc(
        'files',
        {
          ...state.files,
          ...payload.reduce((acc, item) => {
            if (!state.files[item.pieceId]) return acc;
            const nextBarcodeState = unionBy(
              'id',
              [...(state.files[item.pieceId].pieceAnnotations || []), ...(acc[item.pieceId]?.pieceAnnotations || [])],
              [item],
            );
            acc[item.pieceId] = {
              ...state.files[item.pieceId],
              pieceAnnotations: nextBarcodeState,
            };
            return acc;
          }, {} as MapCapturePiece),
        },
        state,
      );
    }

    default:
      return state;
  }
};
