import {
  PieceAnnotation,
  TrackingNumberAnnouncementStatus,
  TrackingNumberValidationStatus,
  Workflow,
} from '@apiContract';
// eslint-disable-next-line import/named
import { filter } from 'lodash/fp';

import { IconColors } from '../components/Capture/types';

type ValidationMap = Map<TrackingNumberValidationStatus, number>;
type AnnounceMap = Map<TrackingNumberAnnouncementStatus, number>;

export type BarcodeListValidationSummary = {
  validation: ValidationMap;
  announce: AnnounceMap;
};

const updateStatusValueMap = (
  map: Map<unknown, number>,
  key: TrackingNumberValidationStatus | TrackingNumberAnnouncementStatus,
) => {
  const keyValue = map.get(key);
  if (keyValue) {
    map.set(key, keyValue + 1);
  } else {
    map.set(key, 1);
  }
};

export const getValidationStatuses = (annotations: Array<PieceAnnotation>): BarcodeListValidationSummary => {
  const validation = new Map<TrackingNumberValidationStatus, number>();
  const announce = new Map<TrackingNumberAnnouncementStatus, number>();

  annotations.forEach((item) => {
    if (item.pieceAnnotationsTrackingNumber) {
      const { announcementStatus, validationStatus } = item.pieceAnnotationsTrackingNumber;
      updateStatusValueMap(validation, validationStatus);
      updateStatusValueMap(announce, announcementStatus);
    }
  });

  return { validation, announce };
};

export const clearServiceAnnotations = (annotations: Array<PieceAnnotation>) =>
  filter(({ textValue }: PieceAnnotation) => !!textValue, annotations);

// green - barcode Lockers - validationStatus: valid + announcementStatus: confirm - only
// gray - barcode Lockers  - isLoading (!annotations.length) || any (validationStatus: valid + announcementStatus: sent)
// red - barcode Lockers - everything else

// green - barcode another - any validationStatus: valid
// gray - barcode another - everything else
// red - barcode another - never

export const getBarcodeIconColor = (annotations: Array<PieceAnnotation>, workflow?: Workflow): IconColors => {
  let color: IconColors = Workflow.Lockers !== workflow ? 'default' : 'error';
  const filteredAnnotation = clearServiceAnnotations(annotations);
  if (!filteredAnnotation.length) return color;
  let i = 0;
  while (color !== 'success' && filteredAnnotation.length > i) {
    const { announcementStatus, validationStatus } = filteredAnnotation[i].pieceAnnotationsTrackingNumber || {};
    if (Workflow.Lockers !== workflow) {
      if (validationStatus === TrackingNumberValidationStatus.Valid) {
        color = 'success';
      }
    } else {
      if (
        validationStatus === TrackingNumberValidationStatus.Valid &&
        announcementStatus === TrackingNumberAnnouncementStatus.Confirmed
      ) {
        color = 'success';
      }
      if (
        validationStatus === TrackingNumberValidationStatus.Valid &&
        announcementStatus === TrackingNumberAnnouncementStatus.Sent
      ) {
        color = 'default';
      }
    }
    i += 1;
  }
  return color;
};

export const isTrackingNumberValid = (annotation: PieceAnnotation, workflow: Workflow) => {
  const { announcementStatus, validationStatus } = annotation.pieceAnnotationsTrackingNumber || {};
  if (workflow === Workflow.Lockers) {
    return (
      validationStatus === TrackingNumberValidationStatus.Valid &&
      announcementStatus === TrackingNumberAnnouncementStatus.Confirmed
    );
  }

  return validationStatus === TrackingNumberValidationStatus.Valid;
};
