import { alpha } from '@mui/material/styles';
import { type Theme } from '@mui/material';

export const sxPaper = {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  minHeight: '100%',
};

export const sxP20 = {
  p: 2.5,
};

export const sxTab = {
  minWidth: 1,
  flexGrow: 1,
  flexDirection: 'column',
};

export const sxForm = {
  ...sxP20,
  justifyContent: 'space-between',
  minWidth: 1,
  flexGrow: 1,
  flexDirection: 'column',
};

export const sxTrackingNumber = (theme: Theme) => ({
  padding: 2.5,
  borderWidth: 1,
  borderColor: alpha(theme.palette.grey[500], 0.16),
  borderStyle: 'solid',
  borderRadius: 1,
});