import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { FC, memo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps, areEqual } from 'react-window';

import { CaptureId, CapturePiece, RecipientStatus } from '../../../../types';
import Search from '@common/components/DrawerContent/Search';
import { sxCenter, sxImgEmpty_60, sxImgEmpty_160, sxP2, sxPieceList } from '../../styles';
import { ProgressStatus } from '../../types';
import Item from './Item';

const EMPTY_NOTIFICATIONS = {
  [RecipientStatus.ready]: { title: 'Nothing in Ready', caption: 'No items are ready' },
  [RecipientStatus.review]: { title: 'Nothing in Review', caption: 'No items require review' },
  [RecipientStatus.invalid]: { title: 'Nothing in Invalid', caption: 'No items are invalid' },
};

type MailPieceListProps = {
  handleClick: (cursor: CaptureId, piece: CapturePiece) => void;
  pieces: Array<CapturePiece>;
  activePiece: CapturePiece | null;
  onSearch: (value: string) => void;
  status: ProgressStatus;
};

const ChildContent = memo(
  ({ index, style, data }: ListChildComponentProps) => (
    <Item
      piece={data.pieces[index]}
      isActive={data.activePiece && data.activePiece.id === data.pieces[index].id}
      style={style}
      handleClick={data.handleClick}
    />
  ),
  areEqual,
);

const MailPieceList: FC<MailPieceListProps> = ({ handleClick, pieces, activePiece, onSearch, status }) => {
  const matchedMobileDevice = useMediaQuery('(pointer: coarse) and (orientation: landscape)');

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <Search setSearchResult={onSearch} debounce={false} sx={sxP2} />
      <Box flex={'1 1 auto'} sx={sxPieceList}>
        {pieces?.length ? (
          <AutoSizer disableWidth>
            {({ height }) => (
              <FixedSizeList
                height={height}
                width="100%"
                itemSize={80}
                itemData={{
                  pieces,
                  activePiece,
                  handleClick,
                }}
                itemCount={pieces.length}
                overscanCount={5}
                className="customScrollBar"
                itemKey={(index) => pieces[index].id}
              >
                {ChildContent}
              </FixedSizeList>
            )}
          </AutoSizer>
        ) : (
          <Stack spacing={1} sx={sxCenter} textAlign="center">
            <img
              src="/assets/icons/empty/ic_folder_empty.svg"
              style={matchedMobileDevice ? sxImgEmpty_60 : sxImgEmpty_160}
              alt='empty folder'
            />
            <Typography color="text.disabled" variant="h6">
              {EMPTY_NOTIFICATIONS[status as RecipientStatus]?.title}
            </Typography>
            <Typography color="text.disabled" variant="caption">
              {EMPTY_NOTIFICATIONS[status as RecipientStatus]?.caption}
            </Typography>
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default MailPieceList;
