import {
  CreatePieceAnnotationRequest,
  PieceAnnotationPagedList,
  PieceAnnotationPagedListResponse,
  PieceAnnotationResponse,
  PieceAnnotationsFilteredListParams,
} from '@apiContract';

import { receivedDigitalApi } from './index';

const pieceAnnotationApi = receivedDigitalApi.injectEndpoints({
  endpoints: (builder) => ({
    createPieceAnnotation: builder.mutation<PieceAnnotationResponse, CreatePieceAnnotationRequest>({
      query: (requestData) => ({
        url: '/piece/annotations',
        method: 'POST',
        body: requestData,
      }),
      invalidatesTags: ['PieceAnnotations'],
    }),
    getPieceAnnotationFiltered: builder.query<PieceAnnotationPagedList, PieceAnnotationsFilteredListParams>({
      query: (params) => ({
        url: '/piece/annotations/filtered',
        params,
      }),
      providesTags: ['PieceAnnotations'],
      transformResponse: (response: PieceAnnotationPagedListResponse) => response.objectData,
    }),
  }),
});

export const { useCreatePieceAnnotationMutation, useGetPieceAnnotationFilteredQuery } = pieceAnnotationApi;
