import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  CaptureMethod,
  ContainerRequest,
  PieceType,
  ReceiveSessionResponse,
  Workflow,
} from '@common/types/apiContract';
import { Options } from '@common/types/capture';
import { SortingOrder, SortingOrderNameByEnum } from '@common/types/contractHelpers';
import { useCreateSessionMutation, useGetSessionByIdQuery } from 'src/redux/api/receiveSession';

import { CurrentSessionProps } from '../types';
import { getPieceTypeByWorkflow } from '@common/utils/piece';

export const useReceiveSession = (sessionId?: string | null) => {
  const [params, setParams] = useSearchParams();
  const [sessionMutation] = useCreateSessionMutation();
  const initSessionValue = useRef(sessionId);
  const [currentSessionProps, setCurrentSessionProps] = useState<CurrentSessionProps>({
    isNew: !!sessionId,
    id: sessionId,
  });

  const {
    data: requestedReceiveSession,
    error: receiveSessionError,
    isLoading,
  } = useGetSessionByIdQuery(initSessionValue.current || null, {
    skip: !initSessionValue.current,
  });

  if (!currentSessionProps.receiveSession && requestedReceiveSession) {
    setCurrentSessionProps({
      isNew: false,
      id: sessionId,
      params: {
        pieceType: getPieceTypeByWorkflow(requestedReceiveSession.workflow),
        workflow: requestedReceiveSession.workflow,
        storageGroupData: requestedReceiveSession.receiveSessionContainer,
      },
      receiveSession: requestedReceiveSession,
    });
  }

  const createSession = async (
    captureMethod: CaptureMethod,
    pieceType: PieceType,
    workflow: Workflow,
    storageGroupData?: ContainerRequest,
  ) => {
    try {
      const response = await sessionMutation({
        captureMethod,
        pieceType,
        workflow,
        receiveSessionContainer: {
          containerTypeId: Number(storageGroupData?.containerTypeId),
          createdDate: storageGroupData?.createdDate || null,
          sortOrder: SortingOrderNameByEnum[(storageGroupData?.sortOrder || SortingOrder.none) as SortingOrder],
          containerNumber: storageGroupData?.containerNumber || '',
        },
      });
      const { data } = response as { data: ReceiveSessionResponse };
      if (data) {
        const id = data.objectData?.id || null;
        setCurrentSessionProps({
          isNew: true,
          id,
          params: {
            pieceType,
            workflow,
            storageGroupData: data.objectData.receiveSessionContainer,
          },
          receiveSession: data.objectData,
        });
        params.delete('sessionId');
        params.append('sessionId', `${id}`);
        setParams(params);
        return id;
      }
    } catch (error) {

      return null;
    }
  };

  const getSessionIdOrCreate = async (options: Options) => {
    if (currentSessionProps?.id) return currentSessionProps.id;
    const { captureMethod, pieceType, workflow, storageGroupData } = options;
    if (!captureMethod || !pieceType || !workflow) {
      throw new Error("can't create receive session");
    }
    const session = await createSession(captureMethod, pieceType, workflow, storageGroupData);
    return session;
  };

  return {
    currentSessionProps,
    receiveSessionError,
    isLoading,
    getSessionIdOrCreate,
  };
};
