import { Box, CircularProgress, InputAdornment, Skeleton, Stack, Theme, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';

import {
  PieceAnnotation,
  TrackingNumberAnnouncementStatus,
  TrackingNumberValidationStatus,
  Workflow,
} from '@apiContract';

import { sxTrackingNumber } from '../styles';
import { getTooltip } from '../utils';

const error = {
  duplicate: 'Tracking number is associated with an existing active item',
  ignore: 'Tracking number is associated with an existing active item and will be ignored',
  badFormat: 'Tracking number has improper format and will be ignored',
  sendFailed: 'Tracking number was unable to be sent to lockers',
};

const getError = (workflow: Workflow, item?: PieceAnnotation, isAnyValid?: boolean) => {
  if (!item) return;

  switch (true) {
    case workflow === Workflow.Lockers &&
      !isAnyValid &&
      item.pieceAnnotationsTrackingNumber?.validationStatus === TrackingNumberValidationStatus.Conflict:
      return {
        message: error.duplicate,
        color: 'error.main',
      };
    case item.pieceAnnotationsTrackingNumber?.validationStatus === TrackingNumberValidationStatus.Conflict:
      return {
        message: error.ignore,
        color: 'text.secondary',
      };
    case item.pieceAnnotationsTrackingNumber?.validationStatus === TrackingNumberValidationStatus.BadFormat:
      return {
        message: error.badFormat,
        color: 'text.secondary',
      };
    case workflow === Workflow.Lockers &&
      item.pieceAnnotationsTrackingNumber?.announcementStatus === TrackingNumberAnnouncementStatus.Failed:
      return {
        message: error.sendFailed,
        color: 'error.main',
      };
    default:
      return null;
  }
};

const Empty = (
  <InputAdornment position="start">
    <Skeleton sx={{ width: 117, height: 14, borderRadius: 0.5 }} />
  </InputAdornment>
);

const Circle = (
  <Box display="flex" justifyContent="center">
    <CircularProgress
      size={22}
      sx={(theme: Theme) => ({
        color: theme.palette.grey[500],
      })}
    />
  </Box>
);

type Props = {
  item?: PieceAnnotation;
  workflow: Workflow;
  isAnyValid?: boolean;
};

const TrackInput: FC<Props> = ({ isAnyValid, item, workflow }) => {
  const startAdornment = item && item.textValue ? null : Empty;
  const endAdornment =
    !item ||
    (Workflow.Lockers === workflow &&
      item.pieceAnnotationsTrackingNumber?.announcementStatus === TrackingNumberAnnouncementStatus.Sent) ||
    !item.textValue
      ? Circle
      : null;

  const tooltip = getTooltip(workflow, item) || null;

  const errorText = getError(workflow, item, isAnyValid);

  return (
    <Tooltip title={tooltip}>
      <Box sx={sxTrackingNumber}>
        <Stack direction="column">
          <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between">
            {startAdornment}
            <Typography variant="body2">{item?.textValue || ''}</Typography>
            {endAdornment}
          </Stack>
          {!!errorText && (
            <Typography color={errorText.color} variant="caption">
              {errorText.message}
            </Typography>
          )}
        </Stack>
      </Box>
    </Tooltip>
  );
};

export default TrackInput;
