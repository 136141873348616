import { Search as SearchIcon } from '@mui/icons-material';
import { Box, CircularProgress, InputAdornment, type SxProps, TextField } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { useDebounce } from 'src/common/hooks/useDebounce';

import { sxSearchCircle, sxSearchIcon } from './styles';

type SearchProps = {
  setSearchResult: (data: string) => void;
  sx?: SxProps;
  defaultSearch?: string | null;
  isLoading?: boolean;
  debounce?: boolean;
};

const Search: FC<SearchProps> = ({ setSearchResult, sx = {}, defaultSearch, isLoading, debounce = true }) => {
  const [value, setValue] = useState(defaultSearch || '');
  const debounceValue = useDebounce(value);

  useEffect(() => {
    if (debounce) {
      setSearchResult(debounceValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  return (
    <Box sx={{ ...sx }}>
      <TextField
        id="outlined-basic"
        placeholder="Search..."
        value={value}
        autoComplete="off"
        onChange={(e) => {
          setValue(e.target.value);
          if (!debounce) {
            setSearchResult(e.target.value);
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={sxSearchIcon} />
            </InputAdornment>
          ),
          endAdornment: (isLoading || (debounce && debounceValue !== value)) && (
            <Box display="flex" justifyContent="center">
              <CircularProgress size={22} sx={sxSearchCircle} />
            </Box>
          ),
        }}
        fullWidth
      />
    </Box>
  );
};

export default Search;
