import { ReactNode, createContext, useState } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';

import { mailroomRoutes } from 'src/routes/paths';
import { Options } from '../types/capture';

type CaptureContextType = {
  setOpen: (data?: Options | object) => void;
  setClose: () => void;
  options: Options | object;
};

export const CaptureContext = createContext<CaptureContextType>({
  setOpen: () => {},
  setClose: () => {},
  options: {} as Options,
});

type SettingsProviderProps = {
  children: ReactNode;
};

export const CaptureProvider = (props: SettingsProviderProps) => {
  const { children } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const [params, setParams] = useSearchParams();

  const [options, setOptions] = useState<Options | object>({});

  const setOpen = (data: Options | object = {}) => {
    setOptions(data);
    params.append('sessionId', 'new');
    setParams(params, { replace: true });
  };

  const setClose = () => {
    const isReceive = location.pathname === mailroomRoutes.receive;

    if (isReceive) {
      params.delete('sessionId');
      params.delete('st');
      params.append('st', Date.now().toString());
    }

    navigate({
      pathname: mailroomRoutes.receive,
      search: isReceive ? new URLSearchParams(params).toString() : '',
    });
  };

  return <CaptureContext.Provider value={{ setOpen, options, setClose }}>{children}</CaptureContext.Provider>;
};
