import PersonIcon from '@mui/icons-material/Person';
import {
  Alert,
  Avatar,
  Box,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { FC } from 'react';

import { Piece, RecipientAutoDetectionStatus, User } from '@apiContract';
import { m } from 'framer-motion';

import { getUserColorPieceStatus, recipientAlerts } from '@common/components/Capture/helper';
import { sxSearchCircle } from '@common/components/Capture/styles';
import { sxAssignButton, sxAssignToBox, sxAvatarBigSize, sxIconGrey500 } from '../styles';
import { Error } from '@mui/icons-material';

type AssignToProps = {
  recipientStatus?: RecipientAutoDetectionStatus;
  user?: User | null;
  showAlert: boolean;
  loading: boolean;
  piece: Piece | null,
};

const AssignTO: FC<AssignToProps> = ({ piece, user, showAlert, recipientStatus, loading = false }) => {
  const theme = useTheme();
  const color = getUserColorPieceStatus(piece, recipientStatus) || 'info';
  const alert = recipientStatus !== undefined ? recipientAlerts[recipientStatus] : null;

  return (
    <Box sx={sxAssignToBox}>
      {showAlert && alert && (
        <m.div
          transition={{
            duration: 2,
            ease: 'easeInOut',
            repeatDelay: 1,
            repeat: Infinity,
          }}
        >
          <Alert icon={color === 'error' ? <Error /> : undefined} severity={color}>{alert}</Alert>
        </m.div>
      )}
      <ListItem disablePadding component="div">
        <Tooltip title={loading ? 'Identifying recipient' : ''}>
          <ListItemButton sx={{ ...sxAssignButton(theme) }}>
            <Box display="flex" flexDirection="row" alignItems="center" flex="1">
              <ListItemAvatar>
                <Avatar sx={sxAvatarBigSize} alt={`${user?.displayName}`} src={user?.profileImageUrl}>
                  {user && !user?.profileImageUrl ? (
                    `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`
                  ) : (
                    <PersonIcon sx={sxIconGrey500} />
                  )}
                </Avatar>
              </ListItemAvatar>
              {user ? (
                <ListItemText
                  primary={<Typography variant="subtitle1">{`${user.firstName} ${user.lastName}`}</Typography>}
                  secondary={
                    <Typography color="text.secondary" variant="body1">
                      {user.userName}
                    </Typography>
                  }
                />
              ) : (
                <Typography variant="subtitle1">{'Unassigned user'}</Typography>
              )}
            </Box>
            {loading && (
              <Box display="flex" justifyContent="center">
                <CircularProgress size={22} sx={sxSearchCircle} />
              </Box>
            )}
          </ListItemButton>
        </Tooltip>
      </ListItem>
    </Box>
  );
};

export default AssignTO;
