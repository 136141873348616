import * as icons from '@mui/icons-material';
import { Box, CircularProgress, SxProps, Tooltip, useTheme } from '@mui/material';
import { BarcodeIcon } from '@src/assets/icons/BarcodeIcon';

// eslint-disable-next-line import/named
import { path } from 'lodash/fp';

import { IconConfig } from '../../../types';
import { styles } from '../styles';

const DefaultIconWithLoader = ({ icon, isLoading, disabled = false, onClick, color, tooltip, sx = {} }: IconConfig) => {
  const theme = useTheme();
  const themeColor = color ? path(['palette', color, 'main'], theme) : undefined;

  const IconComponent = icon === 'BarcodeIcon' ? BarcodeIcon : (icons as any)[icon];

  if (!IconComponent) return null;

  return (
    <Box onClick={!disabled && !!onClick ? onClick : undefined} sx={{ ...styles.container, ...sx } as SxProps}>
      <Tooltip title={tooltip} arrow>
        <IconComponent sx={styles.icon(disabled ? 'action.disabled' : themeColor)} />
      </Tooltip>
      {isLoading && <CircularProgress />}
    </Box>
  );
};

export default DefaultIconWithLoader;
