import { getCapturePieceStatus, getPieceDetection } from '../../../helper';
import { CapturePiece, RecipientStatus } from '../../../types';
import { GroupPiecesStatus, PieceByStatus } from '../types';

export const getPiecesByStatus = (pieces: Array<CapturePiece>, search: string) => {
  const pieceByStatus = pieces.reduce(
    (store, item) => {
      const pieceDetection = getPieceDetection(item);
      const recipient = item.piece?.recipient || pieceDetection?.recipient;
      const lowerSearch = search.toLowerCase();

      if (
        lowerSearch &&
        !recipient?.displayName.toLowerCase().includes(lowerSearch) &&
        !recipient?.userName.toLowerCase().includes(lowerSearch) &&
        !item.pieceAnnotations?.some((annotation) => annotation.textValue?.toLowerCase().includes(lowerSearch))
      ) {
        return store;
      }

      store[GroupPiecesStatus.all].push(item);

      const status = getCapturePieceStatus(item);

      store[status].push(item);

      return store;
    },
    {
      [GroupPiecesStatus.all]: [],
      [RecipientStatus.ready]: [],
      [RecipientStatus.review]: [],
      [RecipientStatus.invalid]: [],
      [GroupPiecesStatus.failed]: [],
    } as PieceByStatus,
  );

  return pieceByStatus;
};
