import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { CSSProperties, useState } from 'react';
import { MotionContainer, varFade } from '@minimalsComponents/animate';
import { m } from 'framer-motion';

import { CaptureId, CurrentSessionProps, DrawerTypes, IdV4, MapCapturePiece, PieceAnnotations, RecipientStatus } from '../../types';
import MailPieceList from './components/MailPieceList';
import PieceView from './components/PieceView';
import ProgressList from './components/ProgressList';
import {
  animationOpenClosed,
  sxCenter,
  sxGridChild_1,
  sxGridChild_1_1,
  sxGridChild_1_2,
  sxGridChild_2,
  sxGridContainer,
  sxImgEmpty_160,
} from './styles';
import { GroupPiecesStatus, ProgressStatus } from './types';
import { getPiecesByStatus } from './utils/getPieces';

type ReviewItemsProps = {
  mapCapturePiece: MapCapturePiece;
  setDrawer: (type: DrawerTypes) => void;
  setCursor: (key: CaptureId | null) => void;
  onCropClick: () => void;
  retryFailedPieces: (id: IdV4) => void;
  defaultCursor?: CaptureId;
  requestedReceiveSession: CurrentSessionProps;
  annotations: PieceAnnotations;
};

const ReviewItems = ({
  setDrawer,
  setCursor,
  onCropClick,
  retryFailedPieces,
  mapCapturePiece,
  defaultCursor,
  requestedReceiveSession,
}: ReviewItemsProps) => {
  const [activePieceId, setActivePieceId] = useState<CaptureId | null>(null);
  const [status, setStatus] = useState<ProgressStatus>(GroupPiecesStatus.all);
  const [searchText, setSearchText] = useState('');
  const capturePieces = Object.values(mapCapturePiece);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const pieceByStatus = getPiecesByStatus(capturePieces, searchText);
  const lengthByStatus = {
    [GroupPiecesStatus.all]: pieceByStatus[GroupPiecesStatus.all].length,
    [RecipientStatus.ready]: pieceByStatus[RecipientStatus.ready].length,
    [RecipientStatus.review]: pieceByStatus[RecipientStatus.review].length,
    [RecipientStatus.invalid]: pieceByStatus[RecipientStatus.invalid].length,
    [GroupPiecesStatus.failed]: pieceByStatus.failed.length,
  };

  const selectPiceId = (pieceId: CaptureId | null) => {
    setActivePieceId(pieceId);
    setCursor(pieceId);
  };

  const activePiece = activePieceId ? mapCapturePiece[activePieceId] : null;

  const isInList = pieceByStatus[status].some(({ id }) => id === activePieceId);

  if (!isInList && activePieceId) {
    selectPiceId(pieceByStatus[status][0]?.id || null);
  }

  if (defaultCursor && mapCapturePiece[defaultCursor] && !activePiece) {
    selectPiceId(defaultCursor);
  }

  const handlePieceClick = (cursor: CaptureId) => {
    selectPiceId(cursor);
  };

  const changeProgressStatus = (data: ProgressStatus) => {
    setStatus(data);
    if (!matches) {
      const isInNextList = pieceByStatus[data].some(({ id }) => id === activePieceId);
      if (isInNextList) return;
      if (data === GroupPiecesStatus.all) {
        selectPiceId(capturePieces[0].id || null);
      } else {
        selectPiceId(pieceByStatus[data][0]?.id || null);
      }
    }
  };

  return (
    <Grid container component={MotionContainer} columnSpacing={{ xs: 0, md: 1 }} sx={sxGridContainer}>
      <Grid item container xs={12} md={5} sx={sxGridChild_1}>
        <Grid item md={4} sx={sxGridChild_1_1}>
          <ProgressList onChange={changeProgressStatus} status={status} data={lengthByStatus} />
        </Grid>
        <Grid container item md={8} sx={sxGridChild_1_2}>
          <MailPieceList
            onSearch={setSearchText}
            handleClick={handlePieceClick}
            pieces={pieceByStatus[status]}
            activePiece={activePiece}
            status={status}
          />
        </Grid>
      </Grid>

      <Grid
        component={m.div}
        container
        initial="closed"
        animate={activePiece ? 'open' : 'closed'}
        variants={matches ? animationOpenClosed : {}}
        transition={varFade().inRight.animate.transition}
        item
        md={7}
        sx={sxGridChild_2}
      >
        {activePiece ? (
          <PieceView
            setDrawer={setDrawer}
            capturePiece={activePiece}
            onCropClick={onCropClick}
            retryFailedPieces={retryFailedPieces}
            currentSession={requestedReceiveSession}
            handleBack={() => {
              setCursor(null);
              setActivePieceId(null);
            }}
          />
        ) : (
          <Box bgcolor="background.paper" width={1} position="relative" borderRadius={1.5}>
            <img
              src="/assets/icons/empty/ic_email_disabled.svg"
              style={{ ...(sxCenter as CSSProperties), ...sxImgEmpty_160 }}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default ReviewItems;
