import { type Theme } from '@mui/material';

import { Steps } from './types';

export const styles = {
  sxDrawer: {
    zIndex: 1300,
  },
  sxDrawerBox: {
    height: '100%',

    '.simplebar-content': {
      height: '100%',
    },
  },
  sxDrawerPaper: {
    sx: { width: 1, maxWidth: 480 },
  },
};

export const sxSearchCircle = (theme: Theme) => ({
  color: theme.palette.grey[500],
});

export const sxMobileGroupBtn = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: 1.5,
  p: 2,
  flexDirection: 'row',
};

export const sxMobileGroupBgColor = {
  [Steps.Images]: { bgcolor: 'background.default' },
  [Steps.ReviewItems]: { bgcolor: 'background.paper' },
};

export const sxNotFound = {
  box: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: 480,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  description: { color: 'text.secondary', textAlign: 'center' },
  buttonBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
};
