import { TypedUseSelectorHook, useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';

import { configureStore } from '@reduxjs/toolkit';

import { receivedDigitalApi, lockersApi } from './api';
import authReducer from './slices/auth';
import pieceSearchReducer from './slices/piceSearch';

const store = configureStore({
  reducer: {
    auth: authReducer,
    pieceSearch: pieceSearchReducer,
    [receivedDigitalApi.reducerPath]: receivedDigitalApi.reducer,
    [lockersApi.reducerPath]: lockersApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(receivedDigitalApi.middleware, lockersApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

// TODO: Move this out to a separate 'hooks.tsx' file
// to avoid potential circular import dependency issues.
const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
const useDispatch = () => useReduxDispatch<AppDispatch>();

export { store, dispatch, useSelector, useDispatch };
